import React, { FC } from 'react';
import Container from 'react-bootstrap/Container';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import GatsbyImage from 'components/common/GatsbyImage';
import useScreenRecognition from 'hooks/useScreenRecognition';
import './MiddleFooter.scss';
import { IMiddleFooter } from './model';

const MiddleFooter: FC<IMiddleFooter> = ({ sponsorsTitle, sponsors, middleCopyright, image }) => {
  const { isMobile, isNotMobile } = useScreenRecognition();

  const renderedSponsorsList = sponsors?.map(({ properties: { logo, label, href } }) => {
    const logoContent = logo[0].properties.logo?.fallbackUrl ? (
      <GatsbyImage
        className="gs-footer-middle__sponsor-logo"
        fluid={logo[0].properties.logo}
        alt={logo[0].properties.altText}
      />
    ) : null;
    const wrappedLogoContent = href ? (
      <a href={href} target="_blank" rel="noreferrer noopener">
        {logoContent}
      </a>
    ) : (
      logoContent
    );

    return (
      <li key={logo[0].properties.logo?.fallbackUrl}>
        {wrappedLogoContent}
        <DangerouslySetInnerHtml className="gs-footer-middle__sponsor-text" html={label} />
      </li>
    );
  });

  return (
    <Container fluid>
      <div className="gs-footer-middle">
        {isMobile ? (
          <DangerouslySetInnerHtml
            className="gs-footer-middle__copyright gs-footer-middle__copyright--sm"
            html={middleCopyright}
          />
        ) : null}
        <div className="gs-footer-middle__wrap">
          <div className="gs-footer-middle__content">
            {isNotMobile ? (
              <DangerouslySetInnerHtml
                className="gs-footer-middle__copyright gs-footer-middle__copyright--lg"
                html={middleCopyright}
              />
            ) : null}

            <div className="gs-footer-middle__sponsors-holder">
              {sponsorsTitle ? (
                <strong className="gs-footer-middle__sponsors-title">{sponsorsTitle}</strong>
              ) : null}

              {sponsors?.length ? (
                <ul className="gs-footer-middle__sponsors">{renderedSponsorsList}</ul>
              ) : null}
            </div>
          </div>
          <div className="gs-footer-middle__image">
            {image?.fallbackUrl ? (
              <GatsbyImage fluid={image} aria-hidden="true" alt={image.altText} />
            ) : null}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default MiddleFooter;
