import React, { FC } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import classnames from 'classnames';
import Container from 'react-bootstrap/Container';

import Header from 'components/Header';
import Footer from 'components/Footer';
import LegalPopup from 'components/LegalPopup';
import Seo from 'components/common/Seo';
import NewsLetter from 'components/NewsLetter';
import DisclaimerPopup from 'components/DisclaimerPopup';
import MobileAppPromoBanner from 'components/MobileAppPromoBanner';
import { getSettingsPropsByStructure } from 'utils/parseHelpers';
import { ILayout } from './model';
import 'styles/main.scss';
import './Layout.scss';

export const queryDefaultProps = graphql`
  fragment FragmentSiteSettings on SITE_SETTINGS {
    id
    umbracoId
    siteUrl
    siteName
    lang
    siteLang
    isRTL
  }
  fragment FragmentDefaultCommonSettingsProps on TCommonSettingsBlockProps {
    skipText
    prevAria
    nextAria
    title
    inFooter
    inHeader
    inBurger
    redirectToHome
    langs {
      properties {
        title
        code
        prefix
        ariaLabel
      }
    }
    ariaOpener
    ariaClose
    description
    opener
    legalPopupTitle
    legalPopupDescription
    legalPopupButton
    legalPopupButtonAria
  }
  fragment FragmentHeader on HEADER {
    logo {
      ...FragmentGatsbyImage
    }
    logoGavi {
      ...FragmentGatsbyImage
    }
    logoAdditionalText
    link {
      url
      name
    }
    logoGaviLink {
      url
      name
    }
    mobileNavigation {
      properties {
        sectionLabel
        links {
          properties {
            label
            link {
              name
              url
            }
          }
        }
      }
    }
    navigation {
      properties {
        label
        link {
          name
          url
        }
        subNavigation {
          properties {
            label
            link {
              name
              url
            }
            subNavigation {
              properties {
                label
                link {
                  name
                  url
                }
              }
            }
          }
        }
      }
    }
    searchPlaceholder
    ariaBurger
    ariaClear
    ariaSearch
    ariaSearchSubmit
    ariaSearchInput
  }
  fragment FragmentFooter on FOOTER {
    image {
      ...FragmentGatsbyImage
    }
    copyright
    copyrightGavinatura
    address {
      properties {
        data
        title
      }
    }
    logo {
      properties {
        altText
        logo {
          ...FragmentGatsbyImage
        }
      }
    }
    logoGavi {
      properties {
        altText
        logo {
          ...FragmentGatsbyImage
        }
      }
    }
    navigation {
      properties {
        title
        links {
          properties {
            link {
              name
              url
            }
          }
        }
        titleLink {
          name
          url
        }
      }
    }
    support {
      properties {
        phone
        title
        titleGavinatura
      }
    }
    sponsorsTitle
    sponsors {
      properties {
        label
        href
        logo {
          properties {
            altText
            logo {
              ...FragmentGatsbyImage
            }
          }
        }
      }
    }
    footerNumber
    bottomLinks {
      properties {
        link {
          url
          name
          target
        }
      }
    }
    middleCopyright
  }
`;

export const query = graphql`
  query LayoutQuery {
    brandSettings {
      brandName
      logo {
        ...FragmentGatsbyImage
      }
    }
    newsletter {
      consentCheckboxes {
        businessId
        consentDescription
        revisionId
      }
      isVisible
      title {
        value
      }
      thankTitle {
        value
      }
      thankText
      thankLogo {
        ...FragmentGatsbyImage
      }
      thankLogoAlt
      shopLink {
        url
        name
      }
      question
      partnerLogo {
        ...FragmentGatsbyImage
      }
      partnerLogoAlt
      namePlaceholder
      link
      label2
      label1
      formTitle
      emailPlaceholder
      copyLabel
      brands {
        properties {
          imageAlt
          link {
            name
            url
          }
          image {
            ...FragmentGatsbyImage
          }
        }
      }
      beforeTitle
      ariaClose
      submitText
      ariaSubmit
      promoCode
      errorName
      errorEmail
      errorServer
      wayinFormSrc
      wayinFormId
      showTimeout
    }
  }
`;

export const CommonSettingsContext = React.createContext({});

const Layout: FC<ILayout> = ({
  children,
  seo,
  siteSettings,
  commonSettings,
  header,
  footer,
  hideNewsletter,
  className,
  isDisclaimerHidden,
  isGaviHeader,
  isGaviDesign,
  isGuardiumDesign,
  isGaviLogo,
  mobileAppPromoBanner,
  pageFooterCopyright,
}) => {
  const { newsletter, brandSettings } = useStaticQuery(query);
  const WAISettings = getSettingsPropsByStructure(commonSettings, 'WAI');
  const lang = siteSettings?.lang;
  const lsSettings = {
    ...getSettingsPropsByStructure(commonSettings, 'Language selector'),
    currentLangCode: lang,
  };
  const disclaimerSettings = getSettingsPropsByStructure(commonSettings, 'Information Popup');
  const legalPopupSettings = getSettingsPropsByStructure(commonSettings, 'Legal Popup');
  const sharedSettings = { ...commonSettings, ...siteSettings };

  const cx = classnames(className, {
    'gavi-design': isGaviDesign,
    'guardium-design': isGuardiumDesign,
  });

  return (
    <div className={cx}>
      <Seo
        siteSettings={siteSettings}
        brandSettings={brandSettings}
        title={seo.seoMetaTitle}
        description={seo.seoMetaDescription}
        externalHreflangs={seo.seoExternalHreflangs}
        keywords={seo.seoMetaKeywords}
        imageAlt={seo.imageAlt}
        imageUrl={seo.imageUrl}
        openGraphImageUrl={seo.openGraphImageUrl}
      />
      <a href="#gs-main" className="gs-skip-main">
        {WAISettings?.skipText}
      </a>
      <Header
        logo={isGaviLogo ? header?.logoGavi : header?.logo}
        logoAdditionalText={header?.logoAdditionalText}
        link={isGaviLogo ? header?.logoGaviLink : header?.link}
        mobileNavigation={header?.mobileNavigation}
        navigation={header?.navigation}
        searchPlaceholder={header?.searchPlaceholder}
        ariaBurger={header?.ariaBurger}
        ariaClear={header?.ariaClear}
        ariaSearch={header?.ariaSearch}
        ariaSearchSubmit={header?.ariaSearchSubmit}
        ariaSearchInput={header?.ariaSearchInput}
        lsSettings={lsSettings}
        isGaviHeader={isGaviHeader}
      />
      <CommonSettingsContext.Provider value={sharedSettings}>
        <main id="gs-main" className="gs-layout__main" role="main">
          {children}
        </main>
      </CommonSettingsContext.Provider>
      {mobileAppPromoBanner ? (
        <Container fluid>
          <MobileAppPromoBanner {...{ ...mobileAppPromoBanner }} />
        </Container>
      ) : null}
      <Footer
        logo={isGaviLogo ? footer?.logoGavi : footer?.logo}
        copyright={
          isGaviLogo ? footer?.copyrightGavinatura : pageFooterCopyright || footer?.copyright
        }
        address={footer?.address}
        navigation={footer?.navigation}
        support={
          isGaviLogo
            ? [
                {
                  properties: {
                    title: footer?.support[0].properties.titleGavinatura,
                    phone: footer?.support[0].properties.phone,
                  },
                },
              ]
            : footer?.support
        }
        sponsors={!isGaviLogo ? footer?.sponsors : null}
        bottomLinks={footer?.bottomLinks}
        image={!isGaviLogo ? footer?.image : null}
        middleCopyright={!isGaviLogo ? footer?.middleCopyright : null}
        lsSettings={lsSettings}
        footerNumber={footer?.footerNumber}
      />
      {!hideNewsletter && newsletter?.isVisible === '1' ? (
        <NewsLetter newsletter={{ ...newsletter, lang }} />
      ) : null}
      {disclaimerSettings?.opener && !isDisclaimerHidden ? (
        <DisclaimerPopup {...disclaimerSettings} />
      ) : null}
      {legalPopupSettings?.legalPopupTitle ? <LegalPopup {...legalPopupSettings} /> : null}
    </div>
  );
};

export default Layout;
